import React, { useState, useRef, ChangeEvent, KeyboardEvent, ClipboardEvent } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

interface FulfillmentTrackingInputProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  shipmentType: { value: string };
  shipmentTypes: { value: string; label: string }[];
  setSubmissionType: (type: string) => void;
}

const FulfillmentTrackingInput: React.FC<FulfillmentTrackingInputProps> = ({
  register,
  setValue,
  shipmentType,
  shipmentTypes,
  setSubmissionType,
}) => {
  const [trackingIds, setTrackingIds] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const MAX_LENGTH = 30;

  const addTrackingIds = (ids: string[]) => {
    const newIds = ids
      .filter((id) => {
        const trimmedId = id.trim();
        return trimmedId && trimmedId.length <= MAX_LENGTH && !trackingIds.includes(trimmedId);
      })
      .map((id) => id.trim());

    if (newIds.length > 0) {
      const updatedTrackingIds = [...trackingIds, ...newIds];
      setTrackingIds(updatedTrackingIds);
      setValue('trackingNumber', updatedTrackingIds);
    }
  };

  const removeTrackingId = (indexToRemove: number) => {
    const updatedTrackingIds = trackingIds.filter((_, index) => index !== indexToRemove);
    setTrackingIds(updatedTrackingIds);
    setValue('trackingNumber', updatedTrackingIds);
  };

  const processInput = (value: string, shouldClear = true) => {
    const hasCommas = value.includes(',');
    const ids = hasCommas ? value.split(',') : value.split(/\s+/);

    const validIds = ids.filter((id) => id.trim()).filter((id) => id.length <= MAX_LENGTH);

    addTrackingIds(validIds);

    if (shouldClear) {
      setInputValue('');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const currentInputs = value.split(/[,\s]+/);
    if (currentInputs.some((id) => id.length > MAX_LENGTH)) {
      return;
    }
    setInputValue(value);

    if (value.endsWith(',') || value.endsWith(' ')) {
      processInput(value);
    }

    register('trackingNumber').onChange(e);
    setSubmissionType('carrierDetails');
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const ids = pastedText.split(/[,\s]+/);

    const validIds = ids.filter((id) => id.trim().length <= MAX_LENGTH);
    const invalidIds = ids.filter((id) => id.trim().length > MAX_LENGTH);

    if (invalidIds.length > 0) {
      setInputValue(invalidIds.join(' '));
    } else {
      processInput(validIds.join(' '), true);
      setInputValue('');
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      processInput(inputValue);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue) {
      e.preventDefault();
      processInput(inputValue);
    } else if (e.key === 'Backspace' && !inputValue && trackingIds.length > 0) {
      setTrackingIds(trackingIds.slice(0, -1));
    }
  };

  return (
    <div className='w-full max-w-2xl'>
      <div className='mb-2 text-sm text-gray-600'>
        Enter tracking numbers (separated by comma or space)
      </div>
      <div className='min-h-20 p-3 border rounded-lg focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500'>
        <div className='flex flex-wrap gap-2'>
          {trackingIds.map((id, index) => (
            <div
              key={`${id}-${index}`}
              className='flex items-center gap-1 px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm'
            >
              <span>{id}</span>
              <button
                onClick={() => removeTrackingId(index)}
                className='p-0.5 hover:bg-blue-200 rounded-full'
              >
                <XMarkIcon className='w-4 h-4' />
              </button>
            </div>
          ))}
          <input
            {...register('trackingNumber')}
            ref={inputRef}
            type='text'
            value={inputValue}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={
              shipmentType.value === shipmentTypes[1].value
                ? 'Enter Pro Number'
                : 'Enter Tracking Number'
            }
            className='flex-1 min-w-[200px] outline-none bg-transparent'
          />
        </div>
      </div>
      {inputValue.length > MAX_LENGTH && (
        <div className='mt-1 text-sm text-red-500'>
          Tracking ID cannot exceed {MAX_LENGTH} characters
        </div>
      )}
    </div>
  );
};

export default FulfillmentTrackingInput;
